/* Add your profile image here */

.Photo {
    background-image: url('https://media-exp1.licdn.com/dms/image/C4D03AQES8xH7zn0Zjg/profile-displayphoto-shrink_400_400/0/1590634682430?e=1614816000&v=beta&t=KNpSNBTe57Jfd-Che_7kbztK0Q3F0BTJKi7BfwzF5pM');
}


/* Projects Photo */

#project11 {
    background-image: url('../images/Jobit-live.png');
    cursor: none;
}

#project10 {
    background-image: url('../images/gallopeat-rider.png');
    cursor: none;
}

#project9 {
    background-image: url('../images/gallopeat-restaurant.png');
    cursor: none;
}

#project8 {
    background-image: url('../images/gallopeat-user.png');
    cursor: none;
}

#project7 {
    background-image: url('../images/Caketools.png');
    cursor: none;
}

#project6 {
    background-image: url('../images/Home-Page.png');
    cursor: none;
}

#project5 {
    background-image: url('../images/Amazon-clone.png');
    cursor: none;
}

#project4 {
    background-image: url('../images/todolist-clone.png');
    cursor: none;
}

#project3 {
    background-image: url('../images/DigiCounter.png');
    cursor: none;
}

#project2 {
    background-image: url('../images/personify.png');
    cursor: none;
}

#project1 {
    background-image: url('../images/covid-19-analysis.png');
    cursor: none;
}


/* Miscelaneous Activities photo */

#misc1 {
    background-image: url('../images/budget_homescreen.png');
    cursor: none;
}

#misc2 {
    background-image: url('../images/restourantscreen.png');
    cursor: none;
}

#misc3 {
    background-image: url('../images/frenzy_home_screen.png');
    cursor: none;
}


/* Contact form parallax effect image */

.parallax {
    background-image: url('https://images.pexels.com/photos/691668/pexels-photo-691668.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
}